function createAdLabel(fontSize, padding, bamAdSlotId, headerHeight) {
  const adLabel = document.createElement("div");
  adLabel.textContent = "ANNONS";
  adLabel.style.cssText = `color:white;background-color: hsla(0, 0%, 0%, 0.50);font-size: ${fontSize};position:fixed;top:${headerHeight};left:0px;font-weight: 700;padding:${padding};z-index: 1;`;

  document.getElementById(bamAdSlotId).appendChild(adLabel);
}

function createArrow(arrowWidth, arrowHeight, bamAdSlotId) {
  const arrow = document.createElement("div");
  arrow.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5));" width="${arrowWidth}" height="${arrowHeight}" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-down">
      <polyline points="7 13 12 18 17 13" />
      <polyline points="7 6 12 11 17 6" />
  </svg>`;
  arrow.style.cssText = `position: absolute; bottom: 0px; left: 0px; width: 100%;height: ${arrowHeight} !important; display: flex !important; align-items: center; justify-content: center; flex-direction: column; pointer-events: none;background-color: hsla(0, 0%, 0%, 0);`;
  document.getElementById(bamAdSlotId).appendChild(arrow);
}

function createCloseButton(closeButtonSize, bamAdSlotId) {
  const closeButton = document.createElement("div");
  closeButton.innerHTML = `<svg style="width: ${closeButtonSize};filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5));" viewBox="0 0 100 100">
      <path d="M0,0 L100,100 M0,100 L100,0" style="fill:none;stroke:white;stroke-width:15"></path>
  </svg>`;
  closeButton.style.cssText = `width: ${closeButtonSize};position: fixed;top: 25px;right: 10px`;
  closeButton.addEventListener("click", () => {
    /*
      There is a choice to be made if we decide on removing the google slot from the page or just scroll past.

      1. if we do remove it we should send out a event that the slot has been collapsed and let the site handle it.

      2. Scroll down to under it, this is the current implementation.
    */

    // Option 1.
    // googletag.destroySlots([googletag.pubads().getSlots().find((slot) => slot.getSlotElementId() === bamAdSlotId)]);
    // publishMessage("collapse", {
    //   slotName: bamAdSlotId.split("-")[0],
    //   slotNumber: bamAdSlotId.split("-")[1],
    //   id: bamAdSlotId,
    // });

    // Option 2.
    const contentStartHeight =
      window.visualViewport.pageTop +
      document.getElementById(bamAdSlotId).getBoundingClientRect().bottom +
      1; //+ 1 to make sure the ad has been scrolled down all the way.

    window.scrollTo({ behavior: "smooth", top: contentStartHeight });
  });
  document.getElementById(bamAdSlotId).appendChild(closeButton);
}

function isAncestor(childWindow, frameWindow) {
  if (frameWindow === childWindow) {
      return true;
  } else if (childWindow === window.top) {
      return false;
  }
  return childWindow?.parent ? isAncestor(childWindow.parent, frameWindow) : null;
}

function getFullPageHeaderElement() {
  const selectors = [
      ".ad-fullpage__header",
      ".fullpage-ad__header",
      "#closePremiumAdWrapper",
      "#fullscreen-ad:first-child",
      "#rich-media-wrapper:first-child",
      "#ad-welcome:first-child"
    ];
  return selectors.map((selector) => document.querySelector(selector)).find((element) => element);
}

function getHeaderElement() {
  const selectors = [
      ".site-header__wrapper",
      ".site-header__row-1",
      "#navbar",
      ".sticky-header",
      ".sticky-menu",
      "#menu-sm",
      ".header-grid",
      ".site-header > div",
  ];
  return selectors
      .map((selector) => document.querySelector(selector))
      .find((element) => element);
}

function seenThisPlugin(event) {
  if (!event.data) return;

  let data, bamAdSlot, adIframe;

  try {
    data = JSON.parse(event.data);

    const frames = document.querySelectorAll('iframe[id*="google_ads_iframe"]');
    adIframe = Array.from(frames).find(frame => isAncestor(event.source, frame.contentWindow));

    bamAdSlot = adIframe.closest("b-a-d");
  } catch (e) {
    return;
  }

  if(!adIframe || !bamAdSlot) return;

  if (data.action === "responsive") {
    const fullpageAdHeader = `${getFullPageHeaderElement() ? getFullPageHeaderElement().offsetHeight : 0}px`;
    const adIframeId = adIframe.id;
    const responsiveStyle = document.createElement("style");
    responsiveStyle.innerHTML = `
      [data-slot-name='rich_media_premium'] [id*='${adIframeId}']{
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
      }
      [data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}']{
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
      }
      [data-slot-name='rich_media_premium'], [data-slot-name='mob_rich_media_premium']{
        height: calc(100% - ${fullpageAdHeader}) !important;}
    `;
    document.head.appendChild(responsiveStyle);
  } else if (data.highImpactRequest) {
    const bamAdSlotToHighImpactFormats = {
      mob: ["midscroll", "mobile_mega"],
      mob_rich_media_premium: ["topscroll", "topscroll_gaming"],
      rich_media_premium: ["topscroll", "topscroll_gaming"],
    };

    const adIframeId = adIframe.id;
    const bamAdSlotId = bamAdSlot.id;

    if (adIframeId === "" || bamAdSlotId === "") return;

    if (data.action === "highImpactSupportedFormat") {
      event.source.postMessage(
        JSON.stringify({
          action: bamAdSlotToHighImpactFormats[bamAdSlot.dataset.slotName],
        }),
        "*"
      );
    } else if (data.action === "midscroll") {
      const headerHeight = `${getHeaderElement()?.offsetHeight || 0}px`;
      const peakAmount = `calc(100vh - ${headerHeight})`;
      const midscrollStyle = document.createElement("style");
      midscrollStyle.textContent = `
          div#${bamAdSlotId} { height: ${peakAmount} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${peakAmount}, 0px ${peakAmount}) !important; margin: 0px 0px 0px -8px !important; padding: 0px !important; position: relative !important;}
          [id='${bamAdSlotId}'] [id*='${adIframeId}'],
          [id='${bamAdSlotId}'] [id*='${adIframeId}'] { height: calc(100vh - ${headerHeight}) !important; width: 100vw !important;clip: rect(auto, auto, auto, auto) !important; position: fixed !important; left: 0px !important; top: ${headerHeight} !important;}
          [id='${bamAdSlotId}'] [class*='ad-info_button'] {display: none !important;}
          `;
      document.head.appendChild(midscrollStyle);

      if (bamAdSlotId.includes("mob")) {
        createAdLabel("10px", "3px 8px", bamAdSlotId, headerHeight);
      } else {
        createAdLabel("14px", "5px 10px", bamAdSlotId, headerHeight);
      }
    } else if (data.action === "topscroll_gaming") {
      const peakAmount = "calc(70vh)";
      const topscrollStyle = document.createElement("style");
      topscrollStyle.textContent = `
          .ad-take-over__header, .ad-welcome__header, .site-header, .fullpage-ad__header, .ad-fullpage__header, #closePremiumAdWrapper, #fullscreen-ad:first-child, #rich-media-wrapper:first-child, #ad-welcome:first-child { display: none !important; }
          div:has(> div#${bamAdSlotId}) { height: ${peakAmount} !important; }
          div#${bamAdSlotId} { height: ${peakAmount} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${peakAmount}, 0px ${peakAmount}) !important; margin: 0px !important; padding: 0px !important; position: relative !important;}
          [data-slot-name='rich_media_premium'] [id*='${adIframeId}'],
          [data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}'] { height: ${peakAmount} !important; width: 100vw !important; clip: rect(auto, auto, auto, auto) !important; position: fixed !important; left: 0px !important; transform: none !important;}
          @keyframes moveAndFade{0%{transform:translateY(0);opacity:1}50%{transform:translateY(50%);opacity:0}100%{transform:translateY(100%);opacity:0}}.feather.feather-chevrons-down polyline{animation:2s linear infinite moveAndFade}
          `;
      document.head.appendChild(topscrollStyle);

      if (bamAdSlotId.includes("mob")) {
        createAdLabel("10px", "3px 8px", bamAdSlotId);
        createArrow("50px", "70px", bamAdSlotId);
        createCloseButton("20px", bamAdSlotId);
      } else {
        createAdLabel("14px", "5px 10px", bamAdSlotId);
        createArrow("70px", "90px", bamAdSlotId);
        createCloseButton("30px", bamAdSlotId);
      }
    } else if (data.action === "topscroll") {
      const peakAmount = "calc(90vh)";
      const topscrollStyle = document.createElement("style");
      topscrollStyle.textContent = `
          .ad-take-over__header, .ad-welcome__header, .site-header, .fullpage-ad__header, #closePremiumAdWrapper, #fullscreen-ad:first-child, #rich-media-wrapper:first-child, #ad-welcome:first-child { display: none !important; }
          div:has(> div#${bamAdSlotId}) { height: ${peakAmount} !important; }
          div#${bamAdSlotId} { height: ${peakAmount} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${peakAmount}, 0px ${peakAmount}) !important; margin: 0px !important; padding: 0px !important; position: relative !important;}
          [data-slot-name='rich_media_premium'] [id*='${adIframeId}'],
        [data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}'] { height: ${peakAmount} !important; width: 100vw !important; clip: rect(auto, auto, auto, auto) !important; position: fixed !important; left: 0px !important; transform: none !important;}
          @keyframes moveAndFade{0%{transform:translateY(0);opacity:1}50%{transform:translateY(50%);opacity:0}100%{transform:translateY(100%);opacity:0}}.feather.feather-chevrons-down polyline{animation:2s linear infinite moveAndFade}
          `;
      document.head.appendChild(topscrollStyle);

      if (bamAdSlotId.includes("mob")) {
        createAdLabel("10px", "3px 8px", bamAdSlotId);
        createArrow("50px", "70px", bamAdSlotId);
        createCloseButton("20px", bamAdSlotId);
      } else {
        createAdLabel("14px", "5px 10px", bamAdSlotId);
        createArrow("70px", "90px", bamAdSlotId);
        createCloseButton("30px", bamAdSlotId);
      }
    } else if (data.action === "mobile_mega") {
      const mobileMegaStyle = document.createElement("style");
      mobileMegaStyle.textContent = `
          div:has(> [id='${adIframeId}']) {width: 100% !important; height: 568px !important;}
          [id='${adIframeId}'] {width: 100% !important; height: 568px !important;}
          div#${bamAdSlotId} { margin: 0px 0px 12px -8px !important; width: 100vw !important;}
          `;
      document.head.appendChild(mobileMegaStyle);
    }
  }
};

export default () => {
  window.addEventListener("message", (event) => {
    seenThisPlugin(event);
  });
};

export { createAdLabel, createArrow, createCloseButton, isAncestor, getFullPageHeaderElement, seenThisPlugin };