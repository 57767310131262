export default () => {
  window.addEventListener("message", (event) => {
    if (event.origin === "https://video.seenthis.se") {
      window.events = window.events || [];
      try {
        window.events.push({ body: document.body.innerHTML })
        const data = JSON.parse(event.data);
        const adIframe = document.querySelector(
          `iframe[src*="${data.adframe}"]`
        );
        const badAdSlot = adIframe.closest("b-a-d");
        if (
          ["rich_media_premium", "mob_rich_media_premium"].includes(
            badAdSlot.getAttribute("slotname")
          ) &&
          (adIframe.width === "1920" || adIframe.width === "640") &&
          data.action === "responsive"
        ) {
          badAdSlot.style.height = `${
            window.innerHeight - badAdSlot.getBoundingClientRect().y
          }px`;
          const adIframeId = adIframe.id;
          const responsiveStyle = document.createElement("style");
          responsiveStyle.id = "responsiveStyle";
          responsiveStyle.innerHTML = `
  [data-slot-name='rich_media_premium'] [id*='${adIframeId}'],
  [data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}']{
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  }
  `;
          document.head.appendChild(responsiveStyle);
        }
      } catch (e) {
        // Do nothing
      }
    }
  });
};
